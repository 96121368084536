class EB_AFIT {
  constructor(container = null, items = null) {
    this.itemsToPack = [];
    this.itemsPackedInOrder = [];
    this.layers = [];
    this.result = new AlgorithmPackingResult();
    this.packingBest = false;
    this.initialize(container, items);
    this.ExecuteIterations(container);
    this.Report(container);

    this.result.AlgorithmID = 1;
    this.result.AlgorithmName = "EB-AFIT";

    for (let i = 1; i <= this.itemsToPackCount; i++) {
      this.itemsToPack[i].Quantity = 1;

      if (!this.itemsToPack[i].IsPacked)
      {
        this.result.UnpackedItems.push(this.itemsToPack[i]);
      }
    }

    this.result.PackedItems = this.itemsPackedInOrder;
    
    if (this.result.UnpackedItems.Count == 0) {
      this.result.IsCompletePack = true;
    }

    return this.result;
  }

  initialize(container, items) {
    this.itemsToPack = [];
    this.itemsPackedInOrder = [];

    this.itemsToPack.push(new Item(0,"",0,0,0,0, "",0,""));

    this.layers = [];
    this.itemsToPackCount = 0;

    items.forEach(item => {

      for(let k=1; k<= item["Quantity"]; k++){
        let newItem = new Item(item["ID"], item["Name"], item["Dim1"], item["Dim2"], item["Dim3"], item["Quantity"], item["UnitMeasure"], item["Weight"], item["WeightType"]);
        this.itemsToPack.push(newItem);
      }
      this.itemsToPackCount += item["Quantity"];
    });

    this.itemsToPack.push(new Item(0,"",0,0,0,0,"",0,""));

    this.totalContainerVolume = container.Length * container.Height * container.Width;
    this.totalWeight = container.Weight;
    this.currentWeight = 0.0;
    this.totalItemVolume = 0.0;

    for (this.x=1; this.x<= this.itemsToPackCount; this.x++){
      this.totalItemVolume = this.totalItemVolume + this.itemsToPack[this.x].Volume;
    }

    this.scrapfirst = new ScrapPad();
    this.scrapfirst.Pre = null;
    this.scrapfirst.Post = null;
    this.packingBest = false;
    this.hundredPercentPacked = false;
    this.quit = false;
  }

  ExecuteIterations(container) {
    let itelayer;
    let layersIndex;
    this.bestVolume = 0.0;
    for (this.containerOrientationVariant = 1; (this.containerOrientationVariant <= 6) && !this.quit; this.containerOrientationVariant++) {
      switch(this.containerOrientationVariant) {
        case 1:
          this.px = container.Length; this.py = container.Height; this.pz = container.Width;
          break;

        case 2:
          this.px = container.Width; this.py = container.Height; this.pz = container.Length;
          break;

        case 3:
          this.px = container.Width; this.py = container.Length; this.pz = container.Height;
          break;

        case 4:
          this.px = container.Height; this.py = container.Length; this.pz = container.Width;
          break;

        case 5:
          this.px = container.Length; this.py = container.Width; this.pz = container.Height;
          break;

        case 6:
          this.px = container.Height; this.py = container.Width; this.pz = container.Length;
          break;
      }
      let tempLayer = new Layer();
      tempLayer.LayerEval = -1;
      this.layers.push(tempLayer);
      this.ListCanditLayers();
      this.layers = this.layers.sort((a,b) => (a.LayerEval > b.LayerEval) ? 1 : ((b.LayerEval > a.LayerEval) ? -1 : 0));

      for (layersIndex = 1; (layersIndex <= this.layerListLen) && !this.quit; layersIndex++) {
        this.packedVolume = 0.0;
        this.packedy = 0;
        this.packing = true;
        this.layerThickness = this.layers[layersIndex].LayerDim;
        this.itelayer = layersIndex;
        this.remainpy = this.py;
        this.remainpz = this.pz;
        this.packedItemCount = 0;

        for (this.x = 1; this.x <= this.itemsToPackCount; this.x++) {
          this.itemsToPack[this.x].IsPacked = false;
        }

        do {
          this.layerinlayer = 0;
          this.layerDone = false;

          this.PackLayer();

          this.packedy = this.packedy + this.layerThickness;
          this.remainpy = this.py - this.packedy;

          if (this.layerinlayer != 0 && !this.quit) {
            this.prepackedy = this.packedy;
            this.preremainpy = this.remainpy;
            this.remainpy = this.layerThickness - this.prelayer;
            this.packedy = this.packedy - this.layerThickness + this.prelayer;
            this.remainpz = this.lilz;
            this.layerThickness = this.layerinlayer;
            this.layerDone = false;

            this.PackLayer();

            this.packedy = this.prepackedy;
            this.remainpy = this.preremainpy;
            this.remainpz = this.pz;
          }

          this.FindLayer(this.remainpy);
        } while(this.packing && !this.quit);

        if ((this.packedVolume > this.bestVolume) && !this.quit) {
          this.bestVolume = this.packedVolume;
          this.bestVariant = this.containerOrientationVariant;
          this.bestIteration = this.itelayer;
        }

        if (this.hundredPercentPacked) break;
      }

      if (this.hundredPercentPacked) break;

      if ((container.Length == container.Height) && (container.Height == container.Width)) this.containerOrientationVariant = 6;

      this.layers.length = 0;
    }
  }

  Report(container) {
    this.quit = false;

    if(this.bestIteration != null) {
      switch (this.bestVariant) {
        case 1:
          this.px = container.Length; this.py = container.Height; this.pz = container.Width;
          break;

        case 2:
          this.px = container.Width; this.py = container.Height; this.pz = container.Length;
          break;

        case 3:
          this.px = container.Width; this.py = container.Length; this.pz = container.Height;
          break;

        case 4:
          this.px = container.Height; this.py = container.Length; this.pz = container.Width;
          break;

        case 5:
          this.px = container.Length; this.py = container.Width; this.pz = container.Height;
          break;

        case 6:
          this.px = container.Height; this.py = container.Width; this.pz = container.Length;
          break;
      }

      this.packingBest = true;

      this.layers.length = 0;

      let tempLayer = new Layer();
      tempLayer.LayerEval = -1;
      this.layers.push(tempLayer);

      this.ListCanditLayers();
      this.layers = this.layers.sort((a,b) => (a.LayerEval > b.LayerEval) ? 1 : ((b.LayerEval > a.LayerEval) ? -1 : 0));
      this.packedVolume = 0;
      this.packedy = 0;
      this.packing = true;
      this.layerThickness = this.layers[this.bestIteration].LayerDim;
      this.remainpy = this.py;
      this.remainpz = this.pz;

      for (this.x = 1; this.x <= this.itemsToPackCount; this.x++) {
        this.itemsToPack[this.x].IsPacked = false;
      }

      do {
        this.layerinlayer = 0;
        this.layerDone = false;
        this.PackLayer();
        this.packedy = this.packedy + this.layerThickness;
        this.remainpy = this.py - this.packedy;

        if (this.layerinlayer > 0.0001) {
          this.prepackedy = this.packedy;
          this.preremainpy = this.remainpy;
          this.remainpy = this.layerThickness - this.prelayer;
          this.packedy = this.packedy - this.layerThickness + this.prelayer;
          this.remainpz = this.lilz;
          this.layerThickness = this.layerinlayer;
          this.layerDone = false;
          this.PackLayer();
          this.packedy = this.prepackedy;
          this.remainpy = this.preremainpy;
          this.remainpz = this.pz;
        }

        if (!this.quit) {
          this.FindLayer(this.remainpy);
        }
      } while (this.packing && !this.quit);
    }
  }

  ListCanditLayers(){
    let same;
    let exdim = 0;
    let dimdif;
    let dimen2 = 0;
    let dimen3 = 0;
    let y;
    let z;
    let k;
    let layereval;

    this.layerListLen = 0;

    for (this.x = 1; this.x <= this.itemsToPackCount; this.x++) {
      for (y = 1; y <= 3; y++) {
        switch (y) {
          case 1:
            exdim = this.itemsToPack[this.x].Dim1;
            dimen2 = this.itemsToPack[this.x].Dim2;
            dimen3 = this.itemsToPack[this.x].Dim3;
            break;

          case 2:
            exdim = this.itemsToPack[this.x].Dim2;
            dimen2 = this.itemsToPack[this.x].Dim1;
            dimen3 = this.itemsToPack[this.x].Dim3;
            break;

          case 3:
            exdim = this.itemsToPack[this.x].Dim3;
            dimen2 = this.itemsToPack[this.x].Dim1;
            dimen3 = this.itemsToPack[this.x].Dim2;
            break;
        }
        if ((exdim > this.py) || (((dimen2 > this.px) || (dimen3 > this.pz)) && ((dimen3 > this.px) || (dimen2 > this.pz)))) continue;
        same = false;
        for (k = 1; k <= this.layerListLen; k++) {
          if (exdim == this.layers[k].LayerDim) {
            same = true;
            continue;
          }
        }

        if(same) continue;

        layereval = 0;
        for (z = 1; z <= this.itemsToPackCount; z++) {
          if (!(this.x == z)) {
            dimdif = this.abs(exdim - this.itemsToPack[z].Dim1);

            if (this.abs(exdim - this.itemsToPack[z].Dim2) < dimdif) {
              dimdif = this.abs(exdim - this.itemsToPack[z].Dim2);
            }
            if (this.abs(exdim - this.itemsToPack[z].Dim3) < dimdif) {
              dimdif = this.abs(exdim - this.itemsToPack[z].Dim3);
            }
            layereval = layereval + dimdif;
          }
        }

        this.layerListLen++;

        this.layers.push(new Layer());
        this.layers[this.layerListLen].LayerEval = layereval;
        this.layers[this.layerListLen].LayerDim = exdim;
      }
    }
  }

  PackLayer() {
    let lenx;
    let lenz;
    let lpz;

    if (this.layerThickness == 0) {
      this.packing = false;
      return;
    }

    this.scrapfirst.CumX = this.px;
    this.scrapfirst.CumZ = 0;

    for(;!this.quit;) {
      this.FindSmallestZ();

      if ((this.smallestZ.Pre == null) && (this.smallestZ.Post == null)) {

        //*** SITUATION-1: NO BOXES ON THE RIGHT AND LEFT SIDES ***

        lenx = this.smallestZ.CumX;
        lpz = this.remainpz - this.smallestZ.CumZ;
        this.FindBox(lenx, this.layerThickness, this.remainpy, lpz, lpz);
        this.CheckFound();

        if (this.layerDone) break;
        if (this.evened) continue;

        this.itemsToPack[this.cboxi].CoordX = 0;
        this.itemsToPack[this.cboxi].CoordY = this.packedy;
        this.itemsToPack[this.cboxi].CoordZ = this.smallestZ.CumZ;

        if (this.cboxx == this.smallestZ.CumX) {
          this.smallestZ.CumZ = this.smallestZ.CumZ + this.cboxz;
        }
        else {
          this.smallestZ.Post = new ScrapPad();

          this.smallestZ.Post.Post = null;
          this.smallestZ.Post.Pre = this.smallestZ;
          this.smallestZ.Post.CumX = this.smallestZ.CumX;
          this.smallestZ.Post.CumZ = this.smallestZ.CumZ;
          this.smallestZ.CumX = this.cboxx;
          this.smallestZ.CumZ = this.smallestZ.CumZ + this.cboxz;
        }
      }
      else if (this.smallestZ.Pre == null) {
        //*** SITUATION-2: NO BOXES ON THE LEFT SIDE ***

        lenx = this.smallestZ.CumX;
        lenz = this.smallestZ.Post.CumZ - this.smallestZ.CumZ;
        lpz = this.remainpz - this.smallestZ.CumZ;
        this.FindBox(lenx, this.layerThickness, this.remainpy, lenz, lpz);
        this.CheckFound();

        if (this.layerDone) break;
        if (this.evened) continue;

        this.itemsToPack[this.cboxi].CoordY = this.packedy;
        this.itemsToPack[this.cboxi].CoordZ = this.smallestZ.CumZ;

        if (this.cboxx == this.smallestZ.CumX) {
          this.itemsToPack[this.cboxi].CoordX = 0;

          if (this.smallestZ.CumZ + this.cboxz == this.smallestZ.Post.CumZ) {
            this.smallestZ.CumZ = this.smallestZ.Post.CumZ;
            this.smallestZ.CumX = this.smallestZ.Post.CumX;
            this.trash = this.smallestZ.Post;
            this.smallestZ.Post = this.smallestZ.Post.Post;

            if (this.smallestZ.Post != null) {
              this.smallestZ.Post.Pre = this.smallestZ;
            }
          }
          else {
            this.smallestZ.CumZ = this.smallestZ.CumZ + this.cboxz;
          }
        }
        else {
          this.itemsToPack[this.cboxi].CoordX = this.smallestZ.CumX - this.cboxx;

          if (this.smallestZ.CumZ + this.cboxz == this.smallestZ.Post.CumZ) {
            this.smallestZ.CumX = this.smallestZ.CumX - this.cboxx;
          }
          else {
            this.smallestZ.Post.Pre = new ScrapPad();

            this.smallestZ.Post.Pre.Post = this.smallestZ.Post;
            this.smallestZ.Post.Pre.Pre = this.smallestZ;
            this.smallestZ.Post = this.smallestZ.Post.Pre;
            this.smallestZ.Post.CumX = this.smallestZ.CumX;
            this.smallestZ.CumX = this.smallestZ.CumX - this.cboxx;
            this.smallestZ.Post.CumZ = this.smallestZ.CumZ + this.cboxz;
          }
        }
      }
      else if (this.smallestZ.Post == null) {
        //*** SITUATION-3: NO BOXES ON THE RIGHT SIDE ***

        lenx = this.smallestZ.CumX - this.smallestZ.Pre.CumX;
        lenz = this.smallestZ.Pre.CumZ - this.smallestZ.CumZ;
        lpz = this.remainpz - this.smallestZ.CumZ;
        this.FindBox(lenx, this.layerThickness, this.remainpy, lenz, lpz);
        this.CheckFound();

        if (this.layerDone) break;
        if (this.evened) continue;

        this.itemsToPack[this.cboxi].CoordY = this.packedy;
        this.itemsToPack[this.cboxi].CoordZ = this.smallestZ.CumZ;
        this.itemsToPack[this.cboxi].CoordX = this.smallestZ.Pre.CumX;

        if (this.cboxx == this.smallestZ.CumX - this.smallestZ.Pre.CumX) {
          if (this.smallestZ.CumZ + this.cboxz == this.smallestZ.Pre.CumZ) {
            this.smallestZ.Pre.CumX = this.smallestZ.CumX;
            this.smallestZ.Pre.Post = null;
          }
          else {
            this.smallestZ.CumZ = this.smallestZ.CumZ + this.cboxz;
          }
        }
        else {
          if (this.smallestZ.CumZ + this.cboxz == this.smallestZ.Pre.CumZ) {
            this.smallestZ.Pre.CumX = this.smallestZ.Pre.CumX + this.cboxx;
          }
          else {
            this.smallestZ.Pre.Post = new ScrapPad();

            this.smallestZ.Pre.Post.Pre = this.smallestZ.Pre;
            this.smallestZ.Pre.Post.Post = this.smallestZ;
            this.smallestZ.Pre = this.smallestZ.Pre.Post;
            this.smallestZ.Pre.CumX = this.smallestZ.Pre.Pre.CumX + this.cboxx;
            this.smallestZ.Pre.CumZ = this.smallestZ.CumZ + this.cboxz;
          }
        }
      }
      else if (this.smallestZ.Pre.CumZ == this.smallestZ.Post.CumZ) {
        //*** SITUATION-4: THERE ARE BOXES ON BOTH OF THE SIDES ***

        //*** SUBSITUATION-4A: SIDES ARE EQUAL TO EACH OTHER ***

        lenx = this.smallestZ.CumX - this.smallestZ.Pre.CumX;
        lenz = this.smallestZ.Pre.CumZ - this.smallestZ.CumZ;
        lpz = this.remainpz - this.smallestZ.CumZ;

        this.FindBox(lenx, this.layerThickness, this.remainpy, lenz, lpz);
        this.CheckFound();

        if (this.layerDone) break;
        if (this.evened) continue;

        this.itemsToPack[this.cboxi].CoordY = this.packedy;
        this.itemsToPack[this.cboxi].CoordZ = this.smallestZ.CumZ;

        if (this.cboxx == this.smallestZ.CumX - this.smallestZ.Pre.CumX) {
          this.itemsToPack[this.cboxi].CoordX = this.smallestZ.Pre.CumX;

          if (this.smallestZ.CumZ + this.cboxz == this.smallestZ.Post.CumZ) {
            this.smallestZ.Pre.CumX = this.smallestZ.Post.CumX;

            if (this.smallestZ.Post.Post != null) {
              this.smallestZ.Pre.Post = this.smallestZ.Post.Post;
              this.smallestZ.Post.Post.Pre = this.smallestZ.Pre;
            }
            else {
              this.smallestZ.Pre.Post = null;
            }
          }
          else {
            this.smallestZ.CumZ = this.smallestZ.CumZ + this.cboxz;
          }
        }
        else if (this.smallestZ.Pre.CumX < this.px - this.smallestZ.CumX) {
          if (this.smallestZ.CumZ + this.cboxz == this.smallestZ.Pre.CumZ) {
            this.smallestZ.CumX = this.smallestZ.CumX - this.cboxx;
            this.itemsToPack[this.cboxi].CoordX = this.smallestZ.CumX;
          }
          else {
            this.itemsToPack[this.cboxi].CoordX = this.smallestZ.Pre.CumX;
            this.smallestZ.Pre.Post = new ScrapPad();

            this.smallestZ.Pre.Post.Pre = this.smallestZ.Pre;
            this.smallestZ.Pre.Post.Post = this.smallestZ;
            this.smallestZ.Pre = this.smallestZ.Pre.Post;
            this.smallestZ.Pre.CumX = this.smallestZ.Pre.Pre.CumX + this.cboxx;
            this.smallestZ.Pre.CumZ = this.smallestZ.CumZ + this.cboxz;
          }
        }
        else {
          if (this.smallestZ.CumZ + this.cboxz == this.smallestZ.Pre.CumZ) {
            this.smallestZ.Pre.CumX = this.smallestZ.Pre.CumX + this.cboxx;
            this.itemsToPack[this.cboxi].CoordX = this.smallestZ.Pre.CumX;
          }
          else {
            this.itemsToPack[this.cboxi].CoordX = this.smallestZ.CumX - this.cboxx;
            this.smallestZ.Post.Pre = new ScrapPad();

            this.smallestZ.Post.Pre.Post = this.smallestZ.Post;
            this.smallestZ.Post.Pre.Pre = this.smallestZ;
            this.smallestZ.Post = this.smallestZ.Post.Pre;
            this.smallestZ.Post.CumX = this.smallestZ.CumX;
            this.smallestZ.Post.CumZ = this.smallestZ.CumZ + this.cboxz;
            this.smallestZ.CumX = this.smallestZ.CumX - this.cboxx;
          }
        }
      }
      else {
        //*** SUBSITUATION-4B: IDES ARE NOT EQUAL TO EACH OTHER ***

        lenx = this.smallestZ.CumX - this.smallestZ.Pre.CumX;
        lenz = this.smallestZ.Pre.CumZ - this.smallestZ.CumZ;
        lpz = this.remainpz - this.smallestZ.CumZ;
        this.FindBox(lenx, this.layerThickness, this.remainpy, lenz, lpz);
        this.CheckFound();

        if (this.layerDone) break;
        if (this.evened) continue;

        this.itemsToPack[this.cboxi].CoordY = this.packedy;
        this.itemsToPack[this.cboxi].CoordZ = this.smallestZ.CumZ;
        this.itemsToPack[this.cboxi].CoordX = this.smallestZ.Pre.CumX;

        if (this.cboxx == (this.smallestZ.CumX - this.smallestZ.Pre.CumX)) {
          if ((this.smallestZ.CumZ + this.cboxz) == this.smallestZ.Pre.CumZ) {
            this.smallestZ.Pre.CumX = this.smallestZ.CumX;
            this.smallestZ.Pre.Post = this.smallestZ.Post;
            this.smallestZ.Post.Pre = this.smallestZ.Pre;
          }
          else {
            this.smallestZ.CumZ = this.smallestZ.CumZ + this.cboxz;
          }
        }
        else {
          if ((this.smallestZ.CumZ + this.cboxz) == this.smallestZ.Pre.CumZ) {
            this.smallestZ.Pre.CumX = this.smallestZ.Pre.CumX + this.cboxx;
          }
          else if (this.smallestZ.CumZ + this.cboxz == this.smallestZ.Post.CumZ) {
            this.itemsToPack[this.cboxi].CoordX = this.smallestZ.CumX - this.cboxx;
            this.smallestZ.CumX = this.smallestZ.CumX - this.cboxx;
          }
          else {
            this.smallestZ.Pre.Post = new ScrapPad();

            this.smallestZ.Pre.Post.Pre = this.smallestZ.Pre;
            this.smallestZ.Pre.Post.Post = this.smallestZ;
            this.smallestZ.Pre = this.smallestZ.Pre.Post;
            this.smallestZ.Pre.CumX = this.smallestZ.Pre.Pre.CumX + this.cboxx;
            this.smallestZ.Pre.CumZ = this.smallestZ.CumZ + this.cboxz;
          }
        }
      }

      this.VolumeCheck();
    }

  }

  FindLayer(thickness) {
    let exdim = 0;
    let dimdif;
    let dimen2 = 0;
    let dimen3 = 0;
    let y;
    let z;
    let layereval;
    let ueval;
    this.layerThickness = 0;
    ueval = 1000000;

    for (this.x = 1; this.x <= this.itemsToPackCount; this.x++) {
      if (this.itemsToPack[this.x].IsPacked) continue;

      for (y = 1; y <= 3; y++) {
        switch(y) {
          case 1:
            exdim = this.itemsToPack[this.x].Dim1;
            dimen2 = this.itemsToPack[this.x].Dim2;
            dimen3 = this.itemsToPack[this.x].Dim3;
            break;

          case 2:
            exdim = this.itemsToPack[this.x].Dim2;
            dimen2 = this.itemsToPack[this.x].Dim1;
            dimen3 = this.itemsToPack[this.x].Dim3;
            break;

          case 3:
            exdim = this.itemsToPack[this.x].Dim3;
            dimen2 = this.itemsToPack[this.x].Dim1;
            dimen3 = this.itemsToPack[this.x].Dim2;
            break;
        }

        layereval = 0;

        if ((exdim <= thickness) && (((dimen2 <= this.px) && (dimen3 <= this.pz)) || ((dimen3 <= this.px) && (dimen2 <= this.pz)))) {
          for (z = 1; z <= this.itemsToPackCount; z++) {
            if (!(this.x == z) && !(this.itemsToPack[z].IsPacked)) {
              dimdif = this.abs(exdim - this.itemsToPack[z].Dim1);

              if (this.abs(exdim - this.itemsToPack[z].Dim2) < dimdif)
              {
                dimdif = this.abs(exdim - this.itemsToPack[z].Dim2);
              }

              if (this.abs(exdim - this.itemsToPack[z].Dim3) < dimdif)
              {
                dimdif = this.abs(exdim - this.itemsToPack[z].Dim3);
              }

              layereval = layereval + dimdif;
            }
          }
          if (layereval < ueval) {
            ueval = layereval;
            this.layerThickness = exdim;
          }
        }
      }
    }

    if (this.layerThickness == 0 || this.layerThickness > this.remainpy) this.packing = false;

  }

  FindSmallestZ() {
    let scrapmemb = this.scrapfirst;
    this.smallestZ = scrapmemb;

    while(scrapmemb.Post != null) {
      if (scrapmemb.Post.CumZ < this.smallestZ.CumZ) {
        this.smallestZ = scrapmemb.Post;
      }
      scrapmemb = scrapmemb.Post;
    }
  }

  FindBox(hmx, hy, hmy, hz, hmz) {
    let y;
    this.bfx = 32767;
    this.bfy = 32767;
    this.bfz = 32767;
    this.bbfx = 32767;
    this.bbfy = 32767;
    this.bbfz = 32767;
    this.boxi = 0;
    this.bboxi = 0;

    for (y = 1; y <= this.itemsToPackCount; y = y + this.itemsToPack[y].Quantity) {
      for (this.x = y; this.x < this.x + this.itemsToPack[y].Quantity - 1; this.x++) {
        if(!this.itemsToPack[this.x].IsPacked) break;
      }

      if (this.itemsToPack[this.x].IsPacked) continue;
      if (this.x > this.itemsToPackCount) return;

      this.AnalyzeBox(hmx, hy, hmy, hz, hmz, this.itemsToPack[this.x].Dim1, this.itemsToPack[this.x].Dim2, this.itemsToPack[this.x].Dim3);
      if ((this.itemsToPack[this.x].Dim1 == this.itemsToPack[this.x].Dim3) && (this.itemsToPack[this.x].Dim3 == this.itemsToPack[this.x].Dim2)) continue;
      
      this.AnalyzeBox(hmx, hy, hmy, hz, hmz, this.itemsToPack[this.x].Dim1, this.itemsToPack[this.x].Dim3, this.itemsToPack[this.x].Dim2);
      this.AnalyzeBox(hmx, hy, hmy, hz, hmz, this.itemsToPack[this.x].Dim2, this.itemsToPack[this.x].Dim1, this.itemsToPack[this.x].Dim3);
      this.AnalyzeBox(hmx, hy, hmy, hz, hmz, this.itemsToPack[this.x].Dim2, this.itemsToPack[this.x].Dim3, this.itemsToPack[this.x].Dim1);
      this.AnalyzeBox(hmx, hy, hmy, hz, hmz, this.itemsToPack[this.x].Dim3, this.itemsToPack[this.x].Dim1, this.itemsToPack[this.x].Dim2);
      this.AnalyzeBox(hmx, hy, hmy, hz, hmz, this.itemsToPack[this.x].Dim3, this.itemsToPack[this.x].Dim2, this.itemsToPack[this.x].Dim1);
    }
  }

  AnalyzeBox(hmx, hy, hmy, hz, hmz, dim1, dim2, dim3){
    if (dim1 <= hmx && dim2 <= hmy && dim3 <= hmz) {
      if (dim2 <= hy) {
        if (hy - dim2 < this.bfy) {
          this.boxx = dim1;
          this.boxy = dim2;
          this.boxz = dim3;
          this.bfx = hmx - dim1;
          this.bfy = hy - dim2;
          this.bfz = this.abs(hz - dim3);
          this.boxi = this.x;
        }
        else if (hy - dim2 == this.bfy && hmx - dim1 < this.bfx) {
          this.boxx = dim1;
          this.boxy = dim2;
          this.boxz = dim3;
          this.bfx = hmx - dim1;
          this.bfy = hy - dim2;
          this.bfz = this.abs(hz - dim3);
          this.boxi = this.x;
        }
        else if (hy - dim2 == this.bfy && hmx - dim1 == this.bfx && this.abs(hz - dim3) < this.bfz) {
          this.boxx = dim1;
          this.boxy = dim2;
          this.boxz = dim3;
          this.bfx = hmx - dim1;
          this.bfy = hy - dim2;
          this.bfz = this.abs(hz - dim3);
          this.boxi = this.x;
        }
      }
      else {
        if (dim2 - hy < this.bbfy) {
          this.bboxx = dim1;
          this.bboxy = dim2;
          this.bboxz = dim3;
          this.bbfx = hmx - dim1;
          this.bbfy = dim2 - hy;
          this.bbfz = this.abs(hz - dim3);
          this.bboxi = this.x;
        }
        else if (dim2 - hy == this.bbfy && hmx - dim1 < this.bbfx) {
          this.bboxx = dim1;
          this.bboxy = dim2;
          this.bboxz = dim3;
          this.bbfx = hmx - dim1;
          this.bbfy = dim2 - hy;
          this.bbfz = this.abs(hz - dim3);
          this.bboxi = this.x;
        }
        else if (dim2 - hy == this.bbfy && hmx - dim1 == this.bbfx && this.abs(hz - dim3) < this.bbfz) {
          this.bboxx = dim1;
          this.bboxy = dim2;
          this.bboxz = dim3;
          this.bbfx = hmx - dim1;
          this.bbfy = dim2 - hy;
          this.bbfz = this.abs(hz - dim3);
          this.bboxi = this.x;
        }
      }
    }
  }

  CheckFound() {
    this.evened = false;

    if(this.boxi != 0) {
      this.cboxi = this.boxi;
      this.cboxx = this.boxx;
      this.cboxy = this.boxy;
      this.cboxz = this.boxz;
    }
    else {
      if ((this.bboxi > 0) && (this.layerinlayer != 0 || (this.smallestZ.Pre == null && this.smallestZ.Post == null))) {
        if (this.layerinlayer == 0) {
          this.prelayer = this.layerThickness;
          this.lilz = this.smallestZ.CumZ;
        }

        this.cboxi = this.bboxi;
        this.cboxx = this.bboxx;
        this.cboxy = this.bboxy;
        this.cboxz = this.bboxz;
        this.layerinlayer = this.layerinlayer + this.bboxy - this.layerThickness;
        this.layerThickness = this.bboxy;
      }
      else {
        if (this.smallestZ.Pre == null && this.smallestZ.Post == null) {
          this.layerDone = true;
        }
        else {
          this.evened = true;

          if (this.smallestZ.Pre == null) {
            this.trash = this.smallestZ.Post;
            this.smallestZ.CumX = this.smallestZ.Post.CumX;
            this.smallestZ.CumZ = this.smallestZ.Post.CumZ;
            this.smallestZ.Post = this.smallestZ.Post.Post;

            if (this.smallestZ.Post != null) {
              this.smallestZ.Post.Pre = this.smallestZ;
            }
          }
          else if (this.smallestZ.Post == null) {
            this.smallestZ.Pre.Post = null;
            this.smallestZ.Pre.CumX = this.smallestZ.CumX;
          }
          else {
            if (this.smallestZ.Pre.CumZ == this.smallestZ.Post.CumZ) {
              this.smallestZ.Pre.Post = this.smallestZ.Post.Post;

              if (this.smallestZ.Post.Post != null) {
                this.smallestZ.Post.Post.Pre = this.smallestZ.Pre;
              }

              this.smallestZ.Pre.CumX = this.smallestZ.Post.CumX;
            }
            else {
              this.smallestZ.Pre.Post = this.smallestZ.Post;
              this.smallestZ.Post.Pre = this.smallestZ.Pre;

              if (this.smallestZ.Pre.CumZ < this.smallestZ.Post.CumZ) {
                this.smallestZ.Pre.CumX = this.smallestZ.CumX;
              }
            }
          }
        }
      }
    }
  }

  VolumeCheck() {
    this.itemsToPack[this.cboxi].IsPacked = true;
    this.itemsToPack[this.cboxi].PackDimX = this.cboxx;
    this.itemsToPack[this.cboxi].PackDimY = this.cboxy;
    this.itemsToPack[this.cboxi].PackDimZ = this.cboxz;
    this.packedVolume = this.packedVolume + this.itemsToPack[this.cboxi].Volume;
    this.packedItemCount++;

    if (this.packingBest) {
      this.OutputBoxList();
    }
    else if (this.packedVolume == this.totalContainerVolume || this.packedVolume == this.totalItemVolume) {
      this.packing = false;
      this.hundredPercentPacked = true;
    }
  }

  OutputBoxList(){
    this.packCoordX = 0;
    this.packCoordY = 0;
    this.packCoordZ = 0;
    this.packDimX = 0;
    this.packDimY = 0;
    this.packDimZ = 0;
    
    // console.log("Total Weight: " + this.totalWeight)
    // console.log("Current Weight: " + this.currentWeight)
    if(this.totalWeight > this.currentWeight && (this.totalWeight >= (this.currentWeight + this.itemsToPack[this.cboxi].Weight))) {
      this.currentWeight += this.itemsToPack[this.cboxi].Weight;

      switch(this.bestVariant) {
        case 1:
          this.packCoordX = this.itemsToPack[this.cboxi].CoordX;
          this.packCoordY = this.itemsToPack[this.cboxi].CoordY;
          this.packCoordZ = this.itemsToPack[this.cboxi].CoordZ;
          this.packDimX = this.itemsToPack[this.cboxi].PackDimX;
          this.packDimY = this.itemsToPack[this.cboxi].PackDimY;
          this.packDimZ = this.itemsToPack[this.cboxi].PackDimZ;
          break;

        case 2:
          this.packCoordX = this.itemsToPack[this.cboxi].CoordZ;
          this.packCoordY = this.itemsToPack[this.cboxi].CoordY;
          this.packCoordZ = this.itemsToPack[this.cboxi].CoordX;
          this.packDimX = this.itemsToPack[this.cboxi].PackDimZ;
          this.packDimY = this.itemsToPack[this.cboxi].PackDimY;
          this.packDimZ = this.itemsToPack[this.cboxi].PackDimX;
          break;

        case 3:
          this.packCoordX = this.itemsToPack[this.cboxi].CoordY;
          this.packCoordY = this.itemsToPack[this.cboxi].CoordZ;
          this.packCoordZ = this.itemsToPack[this.cboxi].CoordX;
          this.packDimX = this.itemsToPack[this.cboxi].PackDimY;
          this.packDimY = this.itemsToPack[this.cboxi].PackDimZ;
          this.packDimZ = this.itemsToPack[this.cboxi].PackDimX;
          break;

        case 4:
          this.packCoordX = this.itemsToPack[this.cboxi].CoordY;
          this.packCoordY = this.itemsToPack[this.cboxi].CoordX;
          this.packCoordZ = this.itemsToPack[this.cboxi].CoordZ;
          this.packDimX = this.itemsToPack[this.cboxi].PackDimY;
          this.packDimY = this.itemsToPack[this.cboxi].PackDimX;
          this.packDimZ = this.itemsToPack[this.cboxi].PackDimZ;
          break;

        case 5:
          this.packCoordX = this.itemsToPack[this.cboxi].CoordX;
          this.packCoordY = this.itemsToPack[this.cboxi].CoordZ;
          this.packCoordZ = this.itemsToPack[this.cboxi].CoordY;
          this.packDimX = this.itemsToPack[this.cboxi].PackDimX;
          this.packDimY = this.itemsToPack[this.cboxi].PackDimZ;
          this.packDimZ = this.itemsToPack[this.cboxi].PackDimY;
          break;

        case 6:
          this.packCoordX = this.itemsToPack[this.cboxi].CoordZ;
          this.packCoordY = this.itemsToPack[this.cboxi].CoordX;
          this.packCoordZ = this.itemsToPack[this.cboxi].CoordY;
          this.packDimX = this.itemsToPack[this.cboxi].PackDimZ;
          this.packDimY = this.itemsToPack[this.cboxi].PackDimX;
          this.packDimZ = this.itemsToPack[this.cboxi].PackDimY;
          break;
      }

      this.itemsToPack[this.cboxi].CoordX = this.packCoordX;
      this.itemsToPack[this.cboxi].CoordY = this.packCoordY;
      this.itemsToPack[this.cboxi].CoordZ = this.packCoordZ;
      this.itemsToPack[this.cboxi].PackDimX = this.packDimX;
      this.itemsToPack[this.cboxi].PackDimY = this.packDimY;
      this.itemsToPack[this.cboxi].PackDimZ = this.packDimZ;

      this.itemsPackedInOrder.push(this.itemsToPack[this.cboxi]);
    }
    else {
      this.itemsToPack[this.cboxi].IsPacked = false;
    }
  }

  abs(num) {
    return num < 0 ? num * -1 : num;
  }

}

export class Item {
  constructor(id, name, dim1, dim2, dim3, quantity, unit_measure, weight, weight_type){
    this.ID=id;
    this.Name=name;
    this.Dim1=dim1;
    this.Dim2=dim2;
    this.Dim3=dim3;
    this.Volume=dim1*dim2*dim3;
    this.Quantity=quantity;
    this.UnitMeasure=unit_measure;
    this.Weight=weight;
    this.WeightType=weight_type;
  }
}

class Container {
  constructor(id, length, width, height, unit_measure){
    this.id=id;
    this.Length=length;
    this.Width=width;
    this.Height=height;
    this.Volume=dim1*dim2*dim3;
    this.UnitMeasure=unit_measure;
  }
}

class ScrapPad{
  constructor(){
    this.CumX = 0;
    this.CumZ = 0;
    this.Post = null;
    this.Pre = null;
  }
}

class Layer {
  constructor() {
    this.LayerDim=0;
    this.LayerEval=0; 
  }

  setLayerDim(LayerDim){
    this.LayerDim=LayerDim;
  }
  setLayerEval(LayerEval){
    this.LayerEval=LayerEval;
  }
}

class AlgorithmPackingResult {
  constructor() {
    this.PackedItems = [];
    this.UnpackedItems = [];
    this.isCompletePack = false;
    this.PackTimeInMilliseconds = 0.0;
    this.PercentContainerVolumePacked=null;
    this.PercentItemVolumePacked=null;
  }
}

export default EB_AFIT