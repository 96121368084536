class AlgorithmPackingResult {
	constructor(result) {
		this.PackedItems = result["PackedItems"];
		this.UnpackedItems = result["UnpackedItems"];
		this.isCompletePack = false;
		this.PackTimeInMilliseconds = 0.0;
		this.PercentContainerVolumePacked=null;
		this.PercentItemVolumePacked=null;
	}

	setAlgorithmID(id) {
		this.id=id;
	}
	getAlgorithmID(){
		return this.id;
	}

	setIsCompletePack(isCompletePack) {
		this.isCompletePack=isCompletePack;
	}
	getIsCompletePack(){
		return this.isCompletePack;
	}

	setPackedItems(PackedItems) {
		this.PackedItems=PackedItems;
	}
	getPackedItems(){
		return this.PackedItems;
	}

	setPackTimeInMilliseconds(PackTimeInMilliseconds) {
		this.PackTimeInMilliseconds=PackTimeInMilliseconds;
	}
	getPackTimeInMilliseconds(){
		return this.PackTimeInMilliseconds;
	}

	setPercentContainerVolumePacked(PercentContainerVolumePacked){
		this.PercentContainerVolumePacked=PercentContainerVolumePacked;
	}
	getPercentContainerVolumePacked(){
		return this.PercentContainerVolumePacked;
	}

	setPercentItemVolumePacked(PercentItemVolumePacked){
		this.PercentItemVolumePacked=PercentItemVolumePacked;
	}
	getPercentItemVolumePacked(){
		return this.PercentItemVolumePacked;
	}

	setUnpackedItems(UnpackedItems){
		this.UnpackedItems=UnpackedItems;
	}
	getUnpackedItems(){
		return this.UnpackedItems;
	}


}

export default AlgorithmPackingResult;