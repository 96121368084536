// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jquery from "jquery"
window.jQuery = jquery
window.$ = jquery

require("modernizr/modernizr.custom.js");

// require("@rails/ujs").start()
// //require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

//--- Bootstrap
import 'bootstrap';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';

import "./modules/order_modal.js"
import "./modules/container-packing.js"

import appInit from './angle/app.init.js';
import consumer from './sellers/consumer.js';

import "./modules/admin_notification";

document.addEventListener('DOMContentLoaded', appInit);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
if ($('.session-message').length > 0 ) {
    setTimeout(() => {
        $('.session-message').remove();
    }, 3000);
}

var $storeId = $("body").find(".store_id").attr("value"); 
var $notifContainer = $("body").find(".orders-notification-container");
if($notifContainer.length) {
    console.log("NOTIFFF SUBscriptionz");
    consumer.subscriptions.create({channel: "OrderProgressChannel", user_id: "seller_"+$storeId}, {
        connected() {
        // Called when the subscription is ready for use on the server
        // console.log("connected")
        },

        disconnected() {
        // console.log("disconnected")
        // Called when the subscription has been terminated by the server
        },

        received(data) {
            console.log('receiveddd', data);
        if(data['type'] == "send_request" && data['order_id'] != null) {
            var $notif = $notifContainer.find(".toast.clone").clone();
            $notif.removeClass("clone hide");
            $notif.addClass("show");
            $notif.attr("orderId", data['order_id']);
            $notif.attr("courierPickupId", data['user_id']);
            $notif.find(".toast-header strong").html(data['company_name']);
            $notif.find(".toast-body .courier-name").html("["+data['name']+"]");
            $notif.find(".toast-body .order-id").html(data['order_id']);
            $notif.appendTo($notifContainer);

            var $forConfirmation = $(".order-collapse .main-ul").find("a[href='/orders/waiting_for_confirmation']").find('span')[0].innerHTML;
            $(".order-collapse .main-ul").find("a[href='/orders/waiting_for_confirmation']").find('span')[0].innerHTML = parseInt($forConfirmation) + 1;

            if (window.location.pathname === "/orders/waiting_for_confirmation") {
            window.location.reload();
            }
        } else if (data['type'] == "pending_order" && data['order_id'] != null) {
            var $pending = $(".order-collapse .main-ul").find("a[href='/orders/pending']").find('span')[0].innerHTML;
            $(".order-collapse .main-ul").find("a[href='/orders/pending']").find('span')[0].innerHTML = parseInt($pending) + 1;

            if (window.location.pathname === "/orders/pending"){
            window.location.reload();
            }
        }
        }
    });

    $notifContainer.on("click", ".toast .accept", function(){
        var $toast = $(this).parents(".toast");
        $toast.find(".accept, .reject").addClass("hide");
        $toast.find(".loading").removeClass("hide");

        $.ajax({
        type: "PUT",
        url:  "/sellers/orders/accept_request",
        data:  {id: $toast.attr("orderId"), courier_pickup_id: $toast.attr("courierPickupId")},
        success: function (result){
            $toast.find(".loading").addClass("hide");
            $toast.find(".fa-check").removeClass("hide");
            var $forConfirmation = $(".order-collapse .main-ul").find("a[href='/orders/waiting_for_confirmation']").find('span')[0].innerHTML;
            $(".order-collapse .main-ul").find("a[href='/orders/waiting_for_confirmation']").find('span')[0].innerHTML = parseInt($forConfirmation) - 1;
            var $activeDelivery = $(".order-collapse .main-ul").find("a[href='/orders/active']").find('span')[0].innerHTML;
            $(".order-collapse .main-ul").find("a[href='/orders/active']").find('span')[0].innerHTML = parseInt($activeDelivery) + 1;
            setTimeout( function(){ 
            $toast.removeClass("show");
            }  , 2000 );
            setTimeout( function(){ 
            $toast.remove();
            }  , 5000 );
        }
        });
    });

    $notifContainer.on("click", ".toast .reject", function(){
        var $toast = $(this).parents(".toast");
        $toast.find(".accept, .reject").addClass("hide");
        $toast.find(".loading").removeClass("hide");
        $.ajax({
        type: "PUT",
        url:  "/sellers/orders/reject_request",
        data:  {id: $toast.attr("orderId"), courier_pickup_id: $toast.attr("courierPickupId")},
        success: function (result){
            $toast.find(".loading").addClass("hide");
            $toast.find(".fa-times").removeClass("hide");
            setTimeout( function(){ 
            $toast.removeClass("show");
            }  , 2000 );
            setTimeout( function(){ 
            $toast.remove();
            }  , 5000 );
        }
        });
    });
}