try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {}

$(document).ready(function(){
  
  if($("#createOrderModal").length) {
    var $sections = [".area-container", ".cart-type-container"];
    var $ctr = 0;

    var $countrySelector = $('.country-selector');
    var $citySelector = $(".city-selector");

    $(".create-order-loading-modal").on("click", ".btn-reload", function(){
      window.location.reload();
    });

    $("#createOrderModal").on("click", ".cart-type-container:not(.disabled) .cart", function(){
      if(!$(this).hasClass("active")) {
        $(this).parents(".cart-type-container").find(".cart").removeClass("active");
        $(this).addClass("active");
      }
    });

    $("#createOrderModal").on("click", ".box-size-container .box-size", function(){
      if(!$(this).hasClass("active")) {
        $(this).parents(".box-size-container").find(".box-size").removeClass("active");
        $(this).addClass("active");
      }
    });

    $("#createOrderModal").on("click", ".delivery-method-container .method", function(){
      var $parent = $(this).parents("#createOrderModal");
      if(!$(this).hasClass("active")) {
        $(this).parents(".delivery-method-container").find(".method").removeClass("active");
        $(this).addClass("active");

        $parent.find(".couriers-container .courier.active").removeClass("active");
        $parent.find(".boxes-type").addClass("hide");
        $parent.find(".box-"+$(this).attr("value")).removeClass("hide");

        if($parent.find(".box-"+$(this).attr("value")+" .box-size").first().length ) 
          $parent.find(".box-"+$(this).attr("value")+" .box-size").first().trigger("click");

        if($parent.find(".couriers-container .courier").first().length ) 
          $parent.find(".couriers-container .courier").first().addClass("active");
      }
    });

    $(document).on("click", ".country-selector", function(e) {
        e.stopPropagation();
        var $tgt = $(e.target);
        $(this).find('ul').slideToggle('fast');
    });

    $(document).on("click", ".country-selector ul li", function(e) {
      $countrySelector.find("ul li").removeClass("active");
      $(this).addClass("active");
      $countrySelector.find(".span.display").html($(this).html());
      $citySelector.find("ul li").addClass("hide");
      if($(this).attr("val") != "") {
        $citySelector.find("ul li." + $(this).attr("val")).removeClass("hide");
      }
      else {
        $citySelector.find("ul li").removeClass("hide");
      }
    });

    $(document).click(function(e) {
        $countrySelector.find('ul:visible').hide();
    });

    $(document).on("click", ".city-selector .display", function(e) {
        e.stopPropagation();
        var $tgt = $(e.target);
        $citySelector.find('ul').slideToggle('fast');
        $citySelector.find(".filter-city").removeClass("hide").focus();
    });

    $(document).on("click", ".city-selector ul li.city, .city-selector ul li.nationwide", function(e) {
      $citySelector.find("ul li").removeClass("active");
      $(this).addClass("active");
      $citySelector.find(".span.display").html($(this).html());
      $citySelector.find(".filter-city").addClass("hide");
    });

    $(document).on("click", this, function(e) {
      $citySelector.find('ul:visible').hide();
      $citySelector.find(".filter-city").addClass("hide");
    });

    $citySelector.on("keyup", ".filter-city", function(){
      let $val = ($(this).val().trim()).toLowerCase();
      let $country = $countrySelector.find("ul li.active").attr("val");
      if ($country == undefined) $country = "philippines";
      filterCity($val, $country);
    });

    $("#createOrderModal").on("click", ".btn.next, .btn.skip", function(){
      var $parent = $("#createOrderModal");
      if( ($ctr + 1) < $sections.length) {
        $ctr++;
        $parent.find(".section").addClass("hide");
        $parent.find($sections[$ctr]).removeClass("hide");
        $parent.find("button.skip").addClass("hide");
        $parent.find("button.back").removeClass("hide");
      }
      else if($ctr == 1) {
        if($parent.find(".cart-type-container .cart.active").attr("value") == "shopping_cart" && $parent.attr("locale") != "ca") {
          $parent.find(".btn-close").click();
          callLoadingModal();
          var $obj = {
            country: $parent.find(".country-selector li.active").attr("val"),
            city: $parent.find(".city-selector li.active").attr("value"),
            cart_type: $parent.find(".cart-type-container .cart.active").attr("value"),
            company_id: parseFloat($parent.find(".company-id").attr("value")),
            is_active: true
          }
          $.ajax({
            type: "PUT",
            url:  "/set_cart",
            data: {cart: $obj},
            success: function (result){
              $('#loadingModal').find(".loading-row").addClass("hide");
              $('#loadingModal').find(".message-row, .button-row").removeClass("hide");
              if(result.status == 200) {
                $('#loadingModal').find(".btn-confirm").addClass("hide");
                $('#loadingModal').find(".btn-reload").removeClass("hide");
                $('#loadingModal').find(".message").removeClass("hide").html(result.message);
              }
              else {
                $('#loadingModal').find(".error").removeClass("hide").html(result.error);
              }
            },
            error: function(result) {
            }
          });
        }
        else if($parent.find(".cart-type-container .cart.active").attr("value") == "balikbayan" || $parent.attr("locale") == "ca") {
          $ctr = 2;
          $parent.find(".btn.next").addClass("hide");
          $parent.find(".btn.confirm").removeClass("hide");
          $parent.find(".cart-type-container").addClass("disabled");
          $parent.find(".box-size-container, .delivery-method-container, .couriers-container").removeClass("hide");
          $parent.find(".box-size-container, .delivery-method-container, .couriers-container").find("input").removeAttr("disabled");
          if($parent.find(".box-vessel:not(.hide)").find(".box-size").first().length ) 
            $parent.find(".box-vessel:not(.hide)").find(".box-size").first().trigger("click");
        }
      }
    });

    $("#createOrderModal").on("click", ".btn.confirm", function(){
      var $parent = $("#createOrderModal");
      callLoadingModal();
      var $obj = {
        address_1: $parent.find("#address_1").val(),
        address_2: $parent.find("#address_2").val(),
        zip_code: $parent.find("#zip_code").val(),
        country: $parent.find(".country-selector li.active").attr("val"),
        city: $parent.find(".city-selector li.active").attr("value"),
        cart_type: $parent.find(".cart-type-container .cart.active").attr("value"), 
        box_size: $parent.find(".box-size-container .box-size.active").attr("value"), 
        delivery_type: $parent.find(".delivery-method-container .method.active").attr("value"), 
        company_id: parseFloat($parent.find(".company-id").attr("value")),
        is_active: true
      }
      $.ajax({
        type: "PUT",
        url:  "/set_cart",
        data: {cart: $obj},
        success: function (result){
          $('#loadingModal').find(".loading-row").addClass("hide");
          $('#loadingModal').find(".message-row, .button-row").removeClass("hide");
          $parent.addClass("hide")
          if(result.status == 200) {
            $('#loadingModal').find(".btn-confirm").addClass("hide");
            $('#loadingModal').find(".btn-reload").removeClass("hide");
            $('#loadingModal').find(".message").removeClass("hide").html(result.message);
          }
          else {
            $('#loadingModal').find(".error").removeClass("hide").html(result.error);
          }
        },
        error: function(result) {
        }
      });
    });

    $("#createOrderModal").on("click", ".btn.back", function(){
      var $parent = $("#createOrderModal");
      if( $ctr == 2) {
        $ctr--;
        $parent.find(".btn.next").removeClass("hide");
        $parent.find(".btn.confirm").addClass("hide");
        $parent.find(".cart-type-container").removeClass("disabled");
        $parent.find(".box-size-container, .delivery-method-container, .couriers-container").addClass("hide");
        $parent.find(".box-size-container, .delivery-method-container, .couriers-container").find("input").attr("disabled", "disabled");
      }
      else if( $ctr > 0) {
        $ctr--;
        $parent.find(".section").addClass("hide");
        $parent.find($sections[$ctr]).removeClass("hide");
        if($ctr == 0) {
          $parent.find("button.skip").removeClass("hide");
          $parent.find("button.back").addClass("hide");
        }
      }
    });

    $(document).on("click",  ".change-cart-container", function(){
      var $changeCart = $(this);
      $(document).find(".country-selector ul li[val='"+$changeCart.find(".country").val()+"']").click();
      $(document).find(".city-selector ul li.city[value='"+$changeCart.find(".city").val()+"']").click();
      $(document).find(".city-selector ul, .country-selector ul").hide();
      $(document).click();

      $(document).find(".cart-type-container .cart[value='"+$changeCart.find(".cart-type").val()+"']").click();
      $(document).find(".box-size-container .box-size[value='"+$changeCart.find(".box-size").val()+"']").click();
      $(document).find(".delivery-method-container .method[value='"+$changeCart.find(".delivery-type").val()+"']").click();
    });

    $("#createOrderModal").on("click", ".couriers-container .courier", function(){
      $.ajax({
        type: "GET",
        url:  "/courier_boxes?id="+$(this).attr("id"),
        success: function (result){
          if(result.status == 200) {
            console.log(result.message)
          }
          else {
            console.log(result.error);
          }
        },
        error: function(result) {
        }
      });
    });
  }

  if($(".order-collapse").length) {
    var $orderCollapse = $(".order-collapse");

    $(document).on("change", ".order-collapse", function(){
      var $this = $(this);
      var $parent = $this.parents("li.orders");
      if($(this).hasClass("active")) {
        $parent.addClass("is-show");
      }
      else {
        $parent.removeClass("is-show");
      }
    });
  }

  $(document).on("click", ".cart-container .user-profile img", function(){
    let $this = $(this).parents(".user-profile");
    $this.toggleClass("show");
    if($this.hasClass("show")) {
      $this.find(".dropdown-menu").addClass("show");
    }
    else {
      $this.find(".dropdown-menu").removeClass("show");
    }
  });

  function filterCity(e, country) {
    var regex = new RegExp('\\b\\w*' + e + '\\w*\\b');
    $citySelector.find("ul li").addClass("hide");
    $citySelector.find("ul li."+country).filter(function () {
      let $val = ($(this).attr("value") + "").toLowerCase();
      return regex.test($val);
    }).removeClass("hide");
  }

  function callLoadingModal(){
    $('#loadingModal').find(".loading-row").removeClass("hide");
    $('#loadingModal').find(".message-row, .button-row").addClass("hide");
    $('#loadingModal').find(".message, .error").addClass("hide").html("");
    $("button.loading-btn").click();
  }
});