import consumer from "./consumer";
$(function(){
  if($(".admin-body").length) {
    consumer.subscriptions.create({channel: "AdminChannel", user_id: "seller_registration"}, {
      connected() {
        // Called when the subscription is ready for use on the server
        // console.log("connected")
      },

      disconnected() {
        // console.log("disconnected")
      },

      received(data) {
        var $notifContainer = $("body").find(".notification-container");
        if(data['type'] == "seller_registration" && data['store'] != null) {
          document.title = "There's a new notification!";
          var $notif = $notifContainer.find(".toast.clone.seller-registration").clone();
          let $store = data['store'];
          $notif.removeClass("clone hide");
          $notif.addClass("show");
          $notif.attr("orderId", $store['id']);
          $notif.find(".toast-header strong").html("Seller Registration");
          $notif.find(".toast-body .courier-name").html("["+$store['business_name']+"]");
          $notif.find(".btn.accept").attr("href", "/sellers/"+$store['id']);
          $notif.appendTo($notifContainer);
          setTimeout(function(){
            removeFadeOut($notif[0], 1000);
          }, 5000);

          $(".admin-sellers-tab").removeClass("hide").html(data['pending_count']);
        }
      }
    });

    function removeFadeOut( el, speed ) {
      var seconds = speed/1000;
      el.style.transition = "opacity "+seconds+"s ease";

      el.style.opacity = 0;
      setTimeout(function() {
          el.parentNode.removeChild(el);
      }, speed);
    }
  }
});